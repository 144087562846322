<template>
  <v-app>
    <v-card>
      <v-card-title class="headline"><v-icon @click="back()" size="30" style="color: black">mdi-arrow-left</v-icon> &nbsp;{{ titleText }} Slider
      </v-card-title>
        <v-card>
          <v-card-title class="headline">Slider Information</v-card-title>
          <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="10">
                  <v-text-field
                      outlined
                      dense
                      label="Slider Title*"
                      v-model="slider.title">
                  </v-text-field>
                  <span class="text-danger" v-if="$v.slider.title.$error">This Title Field is required</span>
                  <span class="text-danger" v-if="errors.title" >**{{errors.title[0]}}</span>
                </v-col>
<!--                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                      outlined
                      dense
                      label="Heading text"
                      v-model="slider.heading_text"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                      outlined
                      dense
                      label="Sub Heading Text*"
                      v-model="slider.subheading_text"
                  ></v-text-field>
                </v-col>-->
                <v-col md="2">
                  <v-text-field v-model="slider.position" label="Position" @keypress="onlyNumber"
                            dense outlined>
                  </v-text-field>
                  <span class="text-danger" v-if="errors.position" >**{{errors.position[0]}}</span>
                  <span class="text-danger" v-if="$v.slider.position.$error">This Position Field is required</span><br>
                  <span class="text-danger" v-if="!$v.slider.position.numeric">This Position Field should be numeric</span>
                </v-col>
                <v-col  v-bind:md="(slider.image != null) ? 8 : 12 ">
                  <v-file-input v-model="slider.image"
                                outlined
                                dense
                                @change="previewImage"
                                placeholder="Upload your documents"
                                label="File input" prepend-icon="mdi-paperclip">

                  </v-file-input>
                  <span class="text-danger" v-if="errors.image" >**{{errors.image[0]}}</span>
                  <span class="text-danger" v-if="$v.slider.image.$error">This Image Field is required</span>

                </v-col>
                <v-col cols="12" sm="12" md="4" v-if="imgurl!=null">
                  <div class="text-center">
                    <v-img
                        :lazy-src="imgurl"
                        max-height="150"
                        max-width="250"
                        class="img-thumbnail"
                        :src="imgurl"></v-img>
                  </div>
                </v-col>
                <v-col md="12">
                  <v-text-field
                          outlined
                          dense
                          label="Heading Text"
                          v-model="slider.heading_text"
                  ></v-text-field>
                </v-col>
                <v-col md="12">
                  <v-text-field
                          outlined
                          dense
                          label="Sub Heading Text"
                          v-model="slider.sub_heading_text"
                  ></v-text-field>
                </v-col>
                <v-col md="12">
                  <v-card-title class="headline">Button</v-card-title>
                </v-col>

                <v-col md="6">
                  <v-switch
                      v-model="slider.show_button"
                      :label="`Button Status `+':'+ ' '+buttonStatusLabel "
                  ></v-switch>
                </v-col>

                <v-col cols="6" sm="6" md="12" v-if="slider.show_button">
                  <v-text-field
                      outlined
                      dense
                      label="Button Text"
                      v-model="slider.button_text"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" sm="6" md="12" v-if="slider.show_button">
                  <v-text-field
                      outlined
                      dense
                      label="Button Link*"
                      v-model="slider.link"
                  ></v-text-field>
                </v-col>

                <v-col
                    cols="12"
                    md="12">
                  Description
                  <ckeditor
                      :config="editorConfig"
                      v-model="slider.description"
                  >
                  </ckeditor>

                </v-col>


                <v-col cols="6">
                  <v-switch
                      v-model="slider.is_active"
                      :label="`Status` + ' : ' + is_activeLabel"
                  ></v-switch>

                  <span class="text-danger" v-if="errors.is_active" >**{{errors.is_active[0]}}</span>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-btn
                      class="ma-2"
                      color="btn btn-primary text-white float-right"
                      @click="createSlider">
                    Save
                  </v-btn>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
    </v-card>
  </v-app>
</template>
<script>
import {required, numeric, requiredIf} from "vuelidate/lib/validators";
import SliderService from "@/services/cms/slider/SliderService";
const slider=new SliderService();
export default {
  validations:{
    slider:{
      title:{required},
      position:{required,numeric},
      image: {
        required: requiredIf(function (nestedModel) {
          return this.sliderId==null;
        })
      },
    }
  },
  data(){
    return{
      title:'',
      imgurl:null,
      slider:{
        title:'',
        type:'',
        heading_text:'',
        subheading_text:'',
        image:null,
        show_button:false,
        button_target:false,
        button_text:'',
        button_link:'',
        second_show_button:false,
        second_button_target:false,
        second_button_text:'',
        second_button_link:'',
        position:'',
        description:'',
        is_active:true,
        visibility:false,
      },
      label: {
        is_activeLabel: '',
        visibilityLabel: '',
        buttonTargetLabel:'',
        buttonStatusLabel:'',
        secondaryButtonStatusLabel:'',
        secondaryButtonTargetLabel:'',
      },
      errors:[],
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [
            'Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'TextColor', 'BGColor'
          ]
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
    }
  },
  methods:{
    back() {
      this.$tabs.close().then(() => {
        this.$tabs.open('/slider');
      });

    },
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.slider) {
        if (key === "image" && this.slider[key] != null && this.slider[key] != undefined) {
          formData.append('image', this.slider[key]);
        }else {
          if(this.slider[key]){
            formData.append(key,this.slider[key]);
          }
        }
      }
      return formData;
    },
    createSlider(){
      this.$v.$touch()
      if (this.$v.$error) {
        var element = this.$v;
        var top = element.offsetTop;
        window.scrollTo(0, top);
        setTimeout(() => {
          this.$v.$reset()
        }, 6000);
      } else {
        if(this.sliderId!=null || this.sliderId!=undefined){
          let formData = this.convertToFormData();
          slider
              .update(this.sliderId,formData)
              .then(response =>{
                this.$snotify.success("Slider updated successfully");
                this.back();
              })
              .catch(error => {
                this.errors = error.response.data.errors;
              });
        }else{
          let formData = this.convertToFormData();
          slider
              .store(formData)
              .then(response =>{
                this.$snotify.success("Slider created successfully");
                this.back();
              })
              .catch(error => {
                this.errors = error.response.data.errors;
              });
        }
      }
    },
    sliderDetail() {
      if (this.sliderId != null || this.sliderId != undefined) {
        slider
        .show(this.sliderId)
            .then(response => {
              this.slider=response.data.slider;
              this.imgurl=this.slider.image_path['real'];


            })
      }
    },
    previewImage(){
      if(this.slider.image != null){

        this.imgurl= URL.createObjectURL(this.slider.image)
      }else{
        this.imgurl = null;
      }
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
  },
  mounted() {
    this.sliderDetail();
  },
  computed:{
    titleText(){
      if(this.sliderId != null || this.sliderId != undefined){
        return this.title='Edit';
      }else{
        return this.title='Add new';
      }
    },
    sliderId () {
      return this.$route.params.sliderId;
    },
    is_activeLabel: function () {
      if(this.slider && this.slider.is_active==true){
        return this.label.is_activeLabel=`Active`
      }else{
        return this.label.is_activeLabel="Inactive"
      }
    },
    visibilityLabel: function () {
      if(this.slider && this.slider.visibility==true){
        return this.label.visibilityLabel="Visible"
      }else{
        return this.label.visibilityLabel="Not Visible"
      }
    },
    buttonStatusLabel:function (){
      if(this.slider && this.slider.show_button==true){
        return this.label.buttonStatusLabel="Available"
      }else{
        return this.label.buttonStatusLabel="Not Available"
      }
    },
    secondaryButtonStatusLabel:function (){
      if(this.slider && this.slider.second_show_button==true){
        return this.label.secondaryButtonStatusLabel="Available"
      }else{
        return this.label.secondaryButtonStatusLabel="Not Available"
      }
    },
    buttonTargetLabel:function (){
      if(this.slider && this.slider.button_target==true){
        return this.label.buttonTargetLabel="Available"
      }else{
        return this.label.buttonTargetLabel="Not Available"
      }
    },
    secondaryButtonTargetLabel:function (){
      if(this.slider && this.slider.second_button_target==true){
        return this.label.secondaryButtonTargetLabel="Available"
      }else{
        return this.label.secondaryButtonTargetLabel="Not Available"
      }
    }
  }
}
</script>
